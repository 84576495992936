import React from 'react'

export default function Privacy(props) {
  return (
    <div className="m-1"
      style={{}}
    >
      <header class="m-3">
        {/* <h1 class="p-5">Contact Us</h1> */}
      </header>
      <div class="px-5">
        {/*wp:heading {"level":1}  */}
        <h1><strong>Privacy Policy</strong></h1>
        {/*/wp:heading  */}

        {/*wp:paragraph  */}
        <p><em>This Privacy Policy was last updated on July 29, 2021.</em></p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>Thank you for joining Nepal’s largest online learning marketplace. We at Grade Plus (<strong>“Grade Plus”</strong>, <strong>“we”</strong>, <strong>“us”</strong>) respect your privacy and want you to understand how we collect, use, and share data about you. This Privacy Policy covers our data collection practices and describes your rights to access, correct, or restrict our use of your personal data.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>Unless we link to a different policy or state otherwise, this Privacy Policy applies when you visit or use theGrade Plus websites, mobile applications, APIs, or related services (the <strong>“Services”</strong>). It also applies to prospective customers of our business and enterprise products.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p><strong>By using the Services, you agree to the terms of this Privacy Policy.</strong> You shouldn’t use the Services if you don’t agree with this Privacy Policy or any other agreement that governs your use of the Services.</p>
        {/*/wp:paragraph  */}

        {/*wp:heading {"level":3}  */}
        <h3><strong>1. What Data We Get</strong></h3>
        {/*/wp:heading  */}

        {/*wp:paragraph  */}
        <p>We collect certain data from you directly, like information you enter yourself, data about your consumption of content, and data from third-party platforms you connect withGrade Plus. We also collect some data automatically, like information about your device and what parts of our Services you interact with or spend time using.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p><strong>1.1 Data You Provide to Us</strong></p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>We may collect different data from or about you depending on how you use the Services. Below are some examples to help you better understand the data we collect.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>When you create an account and use the Services, including through a third-party platform, we collect any data you provide directly, including:</p>
        {/*/wp:paragraph  */}

        {/*wp:table  */}
        <figure class="wp-block-table">
          <table>
            <tbody>
              <tr>
                <td>Account Data</td>
                <td>In order to use certain features (like accessing content), you need to create a user account. When you create or update your account, we collect and store the data you provide, like your email address, password, phone number, occupation, skill interests, gender, race, ethnicity, government ID information, verification photo, age, date of birth, and account settings and assign you a unique identifying number (“Account Data”).</td>
              </tr>
              <tr>
                <td>Profile Data</td>
                <td>You can also choose to provide profile information like a photo, headline, biography, language, website link, social media profiles, country, or other data. Your Profile Data will be publicly viewable by others.</td>
              </tr>
              <tr>
                <td><strong>Shared Content</strong></td>
                <td>Parts of the Services let you interact with other users or share content publicly, including by posting reviews about content, asking or answering questions, sending messages to students or instructors, or posting photos or other work you upload. Such shared content may be publicly viewable by others depending on where it is posted.</td>
              </tr>
              <tr>
                <td><strong>Educational Content Data</strong></td>
                <td>When you access content, we collect certain data including which courses, assignments and quizzes you’ve started and completed; content purchases and credits; subscriptions; completion certificates; your exchanges with instructors, teaching assistants, and other students; and essays, answers to questions, and other items submitted to satisfy course and related content requirements. If you are an instructor, we store your educational content which may contain data about you.</td>
              </tr>
              <tr>
                <td><strong>Student Payment Data</strong></td>
                <td>If you make purchases, we collect certain data about your purchase (such as your name and ZIP code) as necessary to process your order. You must provide certain payment and billing data directly to our payment service providers, including your name, credit card information, billing address, and ZIP code. We may also receive limited information, like the fact that you have a new card and the last four digits of that card, from payment service providers to facilitate payments. For security,Grade Plus does not collect or store sensitive cardholder data, such as full credit card numbers or card authentication data.</td>
              </tr>
              <tr>
                <td><strong>Instructor Payment Data</strong></td>
                <td>If you are an instructor, you can link your PayPal, Payoneer, or other payment account to the Services to receive payments. When you link a payment account, we collect and use certain information, including your payment account email address, account ID, physical address, or other data necessary for us to send payments to your account. In some instances, we may collect ACH or wire information to send payments to your account. In order to comply with applicable laws, we also work with trusted third parties who collect tax information as legally required. This tax information may include residency information, tax identification numbers, biographical information, and other personal information necessary for taxation purposes. For security,Grade Plus does not collect or store sensitive bank account information. The collection, use, and disclosure of your payment, billing, and taxation data is subject to the privacy policy and other terms of your payment account provider.</td>
              </tr>
              <tr>
                <td><strong>Data About Your Accounts on Other Services</strong></td>
                <td>We may obtain certain information through your social media or other online accounts if they are connected to yourGrade Plus account. If you login toGrade Plus via Facebook or another third-party platform or service, we ask for your permission to access certain information about that other account. For example, depending on the platform or service we may collect your name, profile picture, account ID number, login email address, location, physical location of your access devices, gender, birthday, and list of friends or contacts.Those platforms and services make information available to us through their APIs. The information we receive depends on what information you (via your privacy settings) or the platform or service decide to give us.If you access or use our Services through a third-party platform or service, or click on any third-party links, the collection, use, and sharing of your data will also be subject to the privacy policies and other agreements of that third party.</td>
              </tr>
              <tr>
                <td><strong>Sweepstakes, Promotions, and Surveys</strong></td>
                <td>We may invite you to complete a survey or participate in a promotion (like a contest, sweepstakes, or challenge), either through the Services or a third-party platform. If you participate, we will collect and store the data you provide as part of participating, such as your name, email address, postal address, date of birth, or phone number. That data is subject to this Privacy Policy unless otherwise stated in the official rules of the promotion or in another privacy policy. The data collected will be used to administer the promotion or survey, including for notifying winners and distributing rewards. To receive a reward, you may be required to allow us to post some of your information publicly (like on a winner’s page). Where we use a third-party platform to administer a survey or promotion, the third party’s privacy policy will apply.</td>
              </tr>
              <tr>
                <td><strong>Communications and Support</strong></td>
                <td>If you contact us for support or to report a problem or concern (regardless of whether you have created an account), we collect and store your contact information, messages, and other data about you like your name, email address, messages, location,Grade Plus user ID, refund transaction IDs, and any other data you provide or that we collect through automated means (which we cover below). We use this data to respond to you and research your question or concern, in accordance with this Privacy Policy.</td>
              </tr>
            </tbody>
          </table>
        </figure>
        {/*/wp:table  */}

        {/*wp:paragraph  */}
        <p>The data listed above is stored by us and associated with your account.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p><strong>1.2 Data We Collect through Automated Means</strong></p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>When you access the Services (including browsing content), we collect certain data by automated means, including:</p>
        {/*/wp:paragraph  */}

        {/*wp:table  */}
        <figure class="wp-block-table">
          <table>
            <tbody>
              <tr>
                <td><strong>System Data</strong></td>
                <td>Technical data about your computer or device, like your IP address, device type, operating system type and version, unique device identifiers, browser, browser language, domain and other systems data, and platform types (<strong>“System Data”</strong>).</td>
              </tr>
              <tr>
                <td><strong>Usage Data</strong></td>
                <td>Usage statistics about your interactions with the Services, including content accessed, time spent on pages or the Service, pages visited, features used, your search queries, click data, date and time, referrer, and other data regarding your use of the Services (<strong>“Usage Data”</strong>).</td>
              </tr>
              <tr>
                <td><strong>Approximate Geographic Data</strong></td>
                <td>An approximate geographic location, including information like country, city, and geographic coordinates, calculated based on your IP address.</td>
              </tr>
            </tbody>
          </table>
        </figure>
        {/*/wp:table  */}

        {/*wp:paragraph  */}
        <p>The data listed above is collected through the use of server log files and tracking technologies, as detailed in the “Cookies and Data Collection Tools” section below. It is stored by us and associated with your account.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p><strong>1.3 Data From Third Parties</strong></p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>If you are aGrade Plus Business enterprise or corporate prospect, we may collect certain business contact information from third-party commercial sources.</p>
        {/*/wp:paragraph  */}

        {/*wp:heading {"level":3}  */}
        <h3><strong>2. How We Get Data About You</strong></h3>
        {/*/wp:heading  */}

        {/*wp:paragraph  */}
        <p>We use tools like cookies, web beacons, and similar tracking technologies to gather the data listed above. Some of these tools offer you the ability to opt out of data collection.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p><strong>2.1 Cookies and Data Collection Tools</strong></p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>We use cookies, which are small text files stored by your browser, to collect, store, and share data about your activities across websites, including onGrade Plus. They allow us to remember things about your visits toGrade Plus, like your preferred language, and to make the site easier to use. To learn more about cookies, visit https://cookiepedia.co.uk/all-about-cookies.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>Grade Plus and service providers acting on our behalf (like Google Analytics and third-party advertisers) use server log files and automated data collection tools like cookies, tags, scripts, customized links, device or browser fingerprints, and web beacons (together, <strong>“Data Collection Tools“</strong>) when you access and use the Services. These Data Collection Tools automatically track and collect certain System Data and Usage Data (as detailed in Section 1) when you use the Services. In some cases, we tie data gathered through those Data Collection Tools to other data that we collect as described in this Privacy Policy.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p><strong>2.2 Why We Use Data Collection Tools</strong></p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>Grade Plus uses the following types of Data Collection Tools for the purposes described:</p>
        {/*/wp:paragraph  */}

        {/*wp:list  */}
        <ul>
          <li>Strictly Necessary: These Data Collection Tools enable you to access the site, provide basic functionality (like logging in or accessing content), secure the site, protect against fraudulent logins, and detect and prevent abuse or unauthorized use of your account. These are required for the Services to work properly, so if you disable them, parts of the site will break or be unavailable.</li>
          <li>Functional: These Data Collection Tools remember data about your browser and your preferences, provide additional site functionality, customize content to be more relevant to you, and remember settings affecting the appearance and behavior of the Services (like your preferred language or volume level for video playback).</li>
          <li>Performance: These Data Collection Tools help measure and improve the Services by providing usage and performance data, visit counts, traffic sources, or where an application was downloaded from. These tools can help us test different versions of Grade Plus to see which features or content users prefer and determine which email messages are opened.</li>
          <li>Advertising: These Data Collection Tools are used to deliver relevant ads (on the site and/or other sites) based on things we know about you like your Usage and System Data (as detailed in Section 1), and things that the ad service providers know about you based on their tracking data. The ads can be based on your recent activity or activity over time and across other sites and services. To help deliver tailored advertising, we may provide these service providers with a hashed, anonymized version of your email address (in a non-human-readable form) and content that you share publicly on the Services.</li>
          <li>Social Media: These Data Collection Tools enable social media functionality, like sharing content with friends and networks. These cookies may track a user or device across other sites and build a profile of user interests for targeted advertising purposes.</li>
        </ul>
        {/*/wp:list  */}

        {/*wp:paragraph  */}
        <p>You can set your web browser to alert you about attempts to place cookies on your computer, limit the types of cookies you allow, or refuse cookies altogether. If you do, you may not be able to use some or all features of the Services, and your experience may be different or less functional. To learn more about managing Data Collection Tools, refer to Section 6.1 (Your Choices About the Use of Your Data) below.</p>
        {/*/wp:paragraph  */}

        {/*wp:heading {"level":3}  */}
        <h3><strong>3. What We Use Your Data For</strong></h3>
        {/*/wp:heading  */}

        {/*wp:paragraph  */}
        <p>We use your data to do things like provide our Services, communicate with you, troubleshoot issues, secure against fraud and abuse, improve and update our Services, analyze how people use our Services, serve personalized advertising, and as required by law or necessary for safety and integrity.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>We use the data we collect through your use of the Services to:</p>
        {/*/wp:paragraph  */}

        {/*wp:list  */}
        <ul>
          <li>Provide and administer the Services, including to facilitate participation in educational content, issue completion certificates, display customized content, and facilitate communication with other users;</li>
          <li>Process payments to instructors and other third parties;</li>
          <li>Process your requests and orders for educational content, products, specific services, information, or features;</li>
          <li>Communicate with you about your account by:</li>
        </ul>
        {/*/wp:list  */}

        {/*wp:list  */}
        <ul>
          <li>Responding to your questions and concerns;</li>
          <li>Sending you administrative messages and information, including messages from instructors, students, and teaching assistants; notifications about changes to our Service; and updates to our agreements;</li>
          <li>Sending you information, such as by email or text messages, about your progress in courses and related content, rewards programs, new services, new features, promotions, newsletters, and other available instructor-created content (which you can opt out of at any time);</li>
          <li>Sending push notifications to your wireless device to provide updates and other relevant messages (which you can manage from the “options” or “settings” page of the mobile app);</li>
        </ul>
        {/*/wp:list  */}

        {/*wp:heading {"level":3}  */}
        <h3><strong>4. Who We Share Your Data With</strong></h3>
        {/*/wp:heading  */}

        {/*wp:paragraph  */}
        <p>We share certain data about you with instructors, other students, companies performing services for us, Grade Plus affiliates, our business partners, analytics and data enrichment providers, your social media providers, companies helping us run promotions and surveys, and advertising companies who help us promote our Services. We may also share your data as needed for security, legal compliance, or as part of a corporate restructuring. Lastly, we can share data in other ways if it is aggregated or de-identified or if we get your consent.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>We may share your data with third parties under the following circumstances or as otherwise described in this Privacy Policy:</p>
        {/*/wp:paragraph  */}

        {/*wp:list  */}
        <ul>
          <li>With Your Instructors: We share data that we have about you (except your email address) with instructors or teaching assistants for educational content you access or request information about, so they can improve their content for you and other students. This data may include things like your city, country, browser language, operating system, device settings, the site that brought you to Grade Plus, and your activities on Grade Plus. If we collect additional data about you (like age or gender), we may share that too. We will not share your email address with instructors or teaching assistants.</li>
          <li>With Other Students and Instructors: Depending on your settings, your shared content and profile data may be publicly viewable, including to other students and instructors. If you ask a question to an instructor or teaching assistant, your information (including your name) may also be publicly viewable.</li>
          <li>With Service Providers, Contractors, and Agents: We share your data with third-party companies who perform services on our behalf, like payment processing, fraud and abuse prevention, data analysis, marketing and advertising services (including retargeted advertising), email and hosting services, and customer services and support. These service providers may access your personal data and are required to use it solely as we direct, to provide our requested service.</li>
          <li>With Grade Plus Affiliates: We may share your data within our corporate family of companies that are related by common ownership or control to enable or support us in providing the Services.</li>
          <li>With Analytics and Data Enrichment Services: As part of our use of third-party analytics tools like Google Analytics and data enrichment services like ZoomInfo, we share certain contact information, Account Data, System Data, Usage Data (as detailed in Section 1), or de-identified data as needed. De-identified data means data where we’ve removed things like your name and email address and replaced it with a token ID. This allows these providers to provide analytics services or match your data with publicly-available database information (including contact and social information from other sources). We do this to communicate with you in a more effective and customized manner.</li>
          <li>To Power Social Media Features: The social media features in the Services (like the Facebook Like button) may allow the third-party social media provider to collect things like your IP address and which page of the Services you’re visiting, and to set a cookie to enable the feature. Your interactions with these features are governed by the third-party company’s privacy policy.</li>
          <li>To Administer Promotions and Surveys: We may share your data as necessary to administer, market, or sponsor promotions and surveys you choose to participate in, as required by applicable law (like to provide a winners list or make required filings), or in accordance with the rules of the promotion or survey.</li>
          <li>For Advertising: If we decide to use an advertising-supported revenue model in the future, we may use and share certain System Data and Usage Data with third-party advertisers and networks to show general demographic and preference information among our users. We may also allow advertisers to collect System Data through Data Collection Tools (as detailed in Section 2.1), to use this data to offer you targeted ad delivery to personalize your user experience (through behavioral advertising), and to undertake web analytics. Advertisers may also share with us the data they collect about you. To learn more or opt out from participating ad networks’ behavioral advertising, see Section 6.1 (Your Choices About the Use of Your Data) below. Note that if you opt out, you’ll continue to be served generic ads.</li>
          <li>For Security and Legal Compliance: We may disclose your data to third parties if we (in our sole discretion) have a good faith belief that the disclosure is:</li>
        </ul>
        {/*/wp:list  */}

        {/*wp:list  */}
        <ul>
          <li>Permitted or required by law;</li>
          <li>Requested as part of a judicial, governmental, or legal inquiry, order, or proceeding;</li>
          <li>Reasonably necessary as part of a valid subpoena, warrant, or other legally-valid request;</li>
          <li>Reasonably necessary to enforce our Terms of Use, Privacy Policy, and other legal agreements;</li>
          <li>Required to detect, prevent, or address fraud, abuse, misuse, potential violations of law (or rule or regulation), or security or technical issues; or</li>
          <li>Reasonably necessary in our discretion to protect against imminent harm to the rights, property, or safety of Grade Plus, our users, employees, members of the public, or our Services.</li>
          <li>We may also disclose data about you to our auditors and legal advisors in order to assess our disclosure obligations and rights under this Privacy Policy.</li>
        </ul>
        {/*/wp:list  */}

        {/*wp:heading {"level":3}  */}
        <h3><strong>5. Security</strong></h3>
        {/*/wp:heading  */}

        {/*wp:paragraph  */}
        <p>We use appropriate security based on the type and sensitivity of data being stored. As with any internet-enabled system, there is always a risk of unauthorized access, so it’s important to protect your password and to contact us if you suspect any unauthorized access to your account.</p>
        {/*/wp:paragraph  */}

        {/*wp:heading {"level":3}  */}
        <h3><strong>6. Your Rights</strong></h3>
        {/*/wp:heading  */}

        {/*wp:paragraph  */}
        <p>You have certain rights around the use of your data, including the ability to opt out of promotional emails, cookies, and collection of your data by certain third parties. You can update or terminate your account from within our Services, and can also contact us for individual rights requests about your personal data. Parents who believe we’ve unintentionally collected personal data about their underage child should contact us for help deleting that information.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p><strong>6.1 Your Choices About the Use of Your Data</strong></p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>You can choose not to provide certain data to us, but you may not be able to use certain features of the Services.</p>
        {/*/wp:paragraph  */}

        {/*wp:list  */}
        <ul>
          <li>To stop receiving promotional communications from us, you can opt out by using the unsubscribe mechanism in the promotional communication you receive or by changing the email preferences in your account. Note that regardless of your email preference settings, we will send you transactional and relationship messages regarding the Services, including administrative confirmations, order confirmations, important updates about the Services, and notices about our policies.</li>
          <li>The browser or device you use may allow you to control cookies and other types of local data storage. To learn more about managing cookies, visit <a href="https://cookiepedia.co.uk/how-to-manage-cookies" target="_blank" rel="noreferrer noopener">https://cookiepedia.co.uk/how-to-manage-cookies</a>. Your wireless device may also allow you to control whether location or other data is collected and shared.</li>
          <li>Android OS, and Microsoft Windows each provide their own instructions on how to control in-app tailored advertising. For other devices and operating systems, you should review your privacy settings on that platform.</li>
        </ul>
        {/*/wp:list  */}

        {/*wp:paragraph  */}
        <p>If you have any questions about your data, our use of it, or your rights, contact us at mail@Grade Plus.com.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p><strong>6.2 Our Policy Concerning Children</strong></p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>We recognize the privacy interests of children and encourage parents and guardians to take an active role in their children’s online activities and interests. Individuals younger than 18 years of age, but of the required age for consent to use online services where they live (for example, 13 in the US or 16 in Ireland), may not set up an account, but may have a parent or guardian open an account and help them access appropriate content. Individuals younger than the required age for consent to use online services may not use the Services. If we learn that we’ve collected personal data from a child under those ages, we will take reasonable steps to delete it.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>Parents who believe that Grade Plus may have collected personal data from a child under those ages can submit a request that it be removed to mail@Grade Plus.com.</p>
        {/*/wp:paragraph  */}

        {/*wp:heading {"level":3}  */}
        <h3><strong>7. Updates &amp; Contact Info</strong></h3>
        {/*/wp:heading  */}

        {/*wp:paragraph  */}
        <p>When we make a material change to this policy, we’ll notify users via email, in-product notice, or another mechanism required by law. Changes become effective the day they’re posted. Please contact us via email or postal mail with any questions, concerns, or disputes.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p><strong>7.1 Modifications to This Privacy Policy</strong></p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>From time to time, we may update this Privacy Policy. If we make any material change to it, we will notify you via email, through a notification posted on the Services, or as required by applicable law. We will also include a summary of the key changes. Unless stated otherwise, modifications will become effective on the day they are posted.</p>
        {/*/wp:paragraph  */}

        {/*wp:paragraph  */}
        <p>As permitted by applicable law, if you continue to use the Services after the effective date of any change, then your access and/or use will be deemed an acceptance of (and agreement to follow and be bound by) the revised Privacy Policy. The revised Privacy Policy supersedes all previous Privacy Policies.</p>
        {/*/wp:paragraph  */}

      </div>
    </div>
  )
}
