import React from 'react'

export default function ContactUs(props) {
  return (
    <div className="m-1"
      style={{  }}
    >
      <header class="m-3">
        <h1 class="p-5">Contact Us</h1>
      </header>
      <div class="px-5">
        <p> About App<br/><br/>
          GRADE PLUSEUP ACADEMY is an Online education platform which provides digital learning experience with real tutor support according to the nepali curriculum.
          <br/>Contact : 01-5324570, 9860406076,9849474008, 9861106410<br/>
         Gmail : academy.gpa23@gmail.com
          <br/><br/>
          Functions:<br/><br/>
          1.	User Friendly: This app offers a user-friendly log in and registration process, along with simple design which will make users easy to understand.<br/>
          2.	Realistic & Real time : This app offers a realistic exam pattern for MCQ exam module and a real time results with score and displays all correct and incorrect answers immediately.<br/>
          3.	Progress Tracking : You can track your progress report exam result at any time you want.<br/>
       </p>
      </div>
    </div>
  )
}
