import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsDeleteApi, AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import { CSVLink } from "react-csv";
import "./card.css"

export default function AllBatchOrders() {
  const [Projects1, setProjects1] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [StudentsList, setStudentsList] = React.useState([])
  const [BatchList, setBatchList] = React.useState([])
  const [CustomersDownload, setCustomersDownload] = React.useState([])
  const [ImportData, setImportData] = React.useState([])
  const [deleteUsersModal, setDeleteUsersModal] = React.useState(false)

  const [Reload, setReload] = React.useState(false)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachStudent, setSearchStudent] = React.useState("")
  const [serachBatch, setSearchBatch] = React.useState("")
  const [serachBatchID, setSearchBatchID] = React.useState("")
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [BatchTotal, setBatchTotal] = React.useState(0)
  const [choose, setChoose] = React.useState(0)

  const [toStudentModel, setToStudentModal] = React.useState(false)
  const [ChangeToStudentModal, setChangeToStudentModal] = React.useState(false)
  const [ChangeToStudentBatchModal, setChangeToStudentBatchModal] = React.useState(false)

  const GetAllProjects = (params, setData, slug, setTotal) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slug)
      .then((res) => {
        setData(res.data)
        setTotal(res.headers["x-wp-total"])
        if (slug == slugs.db_slug_batch_orders)
          setCustomersList(res.data)
      })
      .catch(err => {
      }).finally(() => {
        setReload(false)
      })
  }

  useEffect(() => {
    GetAll()
  }, [])

  const GetAll = () => {
    setProjects2([])
    GetAllProjects({
      per_page: 4900,
      _fields: "id,title,slug,meta"
    }, setProjects1, slugs.db_slug_batch_orders, setAllTotal)
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,meta"
    }, setBatchList, "student_batch", setBatchTotal)
    GetAllProjects({
      per_page: 4900,
      _fields: "id,title,slug,meta.user_phone_number,meta.batch_name,meta.user_id"
    }, setStudentsList, slugs.db_slug_user_data, setStudentTotal)
  }

  const columnBatch = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
      width: "350px"
    },
    // {
    //   id: 32,
    //   name: "Price",
    //   selector: (row) => row.meta.batch_price,
    //   sortable: true,
    //   reorder: true
    // },
    {
      id: 32,
      name: "Category",
      selector: (row) => row.meta.category_name,
      sortable: true,
      reorder: true
    },
    {
      id: 33,
      name: "Type",
      selector: (row) => row.meta.type,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "View",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.batch_id_order == row.id))
          setChoose(0)
        }}
      >
        Choose
      </div>,
    }
  ];
  const columnsStudent = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.meta.user_id,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "Phone",
      selector: (row) => row.meta.user_phone_number,
      sortable: true,
      reorder: true
    },
    {
      id: 4,
      name: "Batch",
      selector: (row) => row.meta.batch_name,
      sortable: true,
      reorder: true
    },
    {
      id: 5,
      name: "Total Batches",
      selector: (row) => CustomersList.filter(a => a.meta.student_id_order == row.meta.user_id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 6,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects1(CustomersList.filter(a => a.meta.student_id_order == row.meta.user_id))
          setChoose(0)
        }}
      >
        Choose
      </div>,

    }
  ];
  const columns = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "80px",
      compact: true, padding: 0,
    },
    {
      id: 2,
      name: "Batch",
      selector: (row) => row.meta.batch_name_order,
      sortable: true,
      reorder: true,
      compact: true, padding: 0,
    },
    // {
    //   id: 3,
    //   name: "Batch ID",
    //   selector: (row) => row.meta.batch_id_order,
    //   sortable: true,
    //   reorder: true,
    //   compact: true,
    //   padding: 0,
    // },
    {
      id: 4,
      name: "Student Name",
      selector: (row) => row.meta.student_username_order,
      sortable: true,
      center: true,
      reorder: true,
      compact: true,
      padding: 0,
    },
    // {
    //   id: 5,
    //   name: "Phone No",
    //   selector: (row) => row.meta.student_phone_order,
    //   sortable: true,
    //   compact: true, padding: 0,
    //   reorder: true
    // },
    {
      id: 6,
      name: "Verification",
      selector: (row) => row.meta.verification_status,
      sortable: true,
      reorder: true,
      compact: true,
      padding: 0,
    },
    {
      id: 7,
      name: "Amount",
      selector: (row) => row.meta.paid_amount,
      sortable: true,
      reorder: true,
      center: true,
      compact: true,
      padding: 0,
    },
    {
      id: 8,
      name: "Delete",
      center: true,
      compact: true,
      padding: 0,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          AdsDeleteApi(slugs.db_slug_batch_orders, row.id)
            .then((res) => {
              setSearchStudent("")
              setSearchBatch("")
              setSearchBatchID("")
              GetAllProjects({
                per_page: 4900,
                _fields: "id,title,slug,meta"
              }, setProjects1, slugs.db_slug_batch_orders, setAllTotal)

            })
            .catch((err) => {
            })
        }}
      >Delete</div>,

    },
    {
      id: 32,
      name: "View",
      center: true,
      compact: true,
      padding: 0,
      cell: (row) => <Link to={`${slugs.batch_order_details}${row.slug}`}>View</Link>,

    }
  ];

  const sortForDownload = (DataArray) => {
    var dData = []
    for (let i = 0; i < DataArray.length; i++) {
      var item = DataArray[i]
      dData.push({
        id: item.id,
        name: item.title.rendered,
        phone_number: item.meta.user_phone_number,
        email: item.meta.user_email,
        login_user_name: item.meta.user_name,
        password: item.meta.user_password,
        role: "student"
      })
    }
    setCustomersDownload(dData)
    console.log("done", dData)
  }

  const [file, setFile] = React.useState();
  const fileReader = new FileReader();
  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    e.preventDefault();
    const file = e.target.files[0]
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        const jsonData = convertCSVToJson(csvOutput);

        // console.log(jsonData)
        var newData = []
        for (let i = 0; i < jsonData.length; i++) {
          const jsonDatai = jsonData[i]
          // console.log(jsonDatai)
          // const searchEmail = Projects1.filter(a => a.meta.user_name == jsonDatai["Login Name"])
          const searchEmail = Projects1.filter(a => a.meta.user_email == jsonDatai["Email"])
          // console.log(jsonDatai, "asddasd", searchEmail.length)
          const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
          function validateEmail(email) {
            return emailRegex.test(email);
          }
          const isValidEmail = validateEmail(jsonDatai["Email"]);
          var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          var passwordLength = 12;
          var password = "";
          for (let l = 0; l <= passwordLength; l++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
          }
          // console.log(isValidEmail,jsonDatai["Email"],jsonDatai)
          if (isValidEmail)
            if (searchEmail.length == 0)
              newData.push({
                "title": { rendered: jsonDatai["Name"], },
                "status": "publish",
                "meta": {
                  "user_role": jsonDatai["User Role"],
                  "user_name": jsonDatai["Login Name"],
                  "user_password": jsonDatai["Password"] == "" ? password : jsonDatai["Password"],
                  "user_phone_number": jsonDatai["Phone Number"],
                  "user_email": jsonDatai["Email"],
                }
              })
          // console.log(newData)
          setImportData(newData)
        }
      };
      fileReader.readAsText(file);
    };
  }

  const convertCSVToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].replace("\r", "").split(",");
    const result = [];
    console.log("asdasd", headers, lines[0])
    for (let i = 1; i < lines.length; i++) {
      var obj = {};
      const currentLine = lines[i].replace("\r", "").split(",");
      // const currentLine = lines[i].split(",");
      console.log(currentLine, lines[i])
      for (let j = 0; j < headers.length; j++) {
        // obj[headers[j].trim()] = currentLine[j].trim();
        console.log(currentLine[j])
        var key = headers[j]
        obj = {
          ...obj,
          [key]: currentLine[j]
        }
      }
      console.log(obj)

      result.push(obj);
    }
    // setImportData(result)
    return result;
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Phone Number", key: "phone_number" },
    { label: "Email", key: "email" },
    { label: "Login Name", key: "login_user_name" },
    { label: "Password", key: "password" },
    { label: "User Role", key: "role" },
    { label: "User ID", key: "id" }
  ];
  const fileRefSingle = React.createRef();
  const date = new Date();

  const arrayUniqueByKey = [...new Map(Projects1.map((item) =>
    [item.meta.student_id_order, item])).values()];

  const VerifiedList = ({ item }) => {
    return (
      <div>
        {item.meta.batch_name_order}
      </div>
    )
  }

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Orders</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Batch Orders
        </div>
      </div>
      <a href={slugs.new_batch_order}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Batch Order</div>
      </a>
      <div className="row">
        <div className="col-md-7" style={{ paddingLeft: "40px" }}>
          <div>
            <div style={{ display: "inline", cursor: "pointer" }}>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(1)
                }}
              >
                Batch ({BatchTotal}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(2)
                }}
              >
                Students ({StudentTotal}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList)
                }}
              >
                Clear |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setDeleteUsersModal(true)
                }}
              >
                Delete Orders
              </div>
              {/* <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setImportData([])
                  setToStudentModal(true)
                }}
              >
                Add Multiple |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}>
                <CSVLink data={CustomersDownload} headers={headers} className="Link"
                  filename={`StudentData-${date.toLocaleString()}.csv`}
                >

                  Download CSV
                </CSVLink>
              </div> */}
            </div>
          </div>
          <div>
            <div style={{ display: "inline", cursor: "pointer" }}>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList)
                }}
              >
                All Orders ({AllTotal}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList.filter(a => a.meta.verification_status == "Verified"))
                }}
              >
                Verified ({CustomersList.filter(a => a.meta.verification_status == "Verified").length}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList.filter(a => a.meta.verification_status == "Unverified"))
                }}
              >
                Unverified ({CustomersList.filter(a => a.meta.verification_status == "Unverified").length}) |
              </div>
              <div style={{ display: "inline-block", margin: "0px 5px" }}
                onClick={() => {
                  setChoose(0)
                  setProjects1(CustomersList.filter(a => a.meta.verification_status == "Unverified" && a.meta.verification_method == "Manual" && a.meta.verification_id.includes("https://")))
                }}
              >
                Manual ({CustomersList.filter(a => a.meta.verification_status == "Unverified" && a.meta.verification_method == "Manual" && a.meta.verification_id.includes("https://")).length}) |
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 row">
          <div className="col-4" />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachBatch} placeholder="Batch Name"
            onChange={(event) => {
              setSearchBatch(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.batch_name_results.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachBatchID} placeholder="Batch ID"
            onChange={(event) => {
              setSearchBatchID(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.batch_id_order.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachStudent} placeholder="Student ID"
            onChange={(event) => {
              setSearchStudent(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.student_id_results.includes(event.target.value)))
            }}
          />
        </div>
      </div>


      <div style={{ padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&

          <>
            {/* {arrayUniqueByKey.map((item, index) => (
              <div key={index}>
                <div style={{ width: "100%", margin: "10px", }}>
                  <div class="card">
                    <h3 class="card__title">{item.meta.student_username_order}
                    </h3>
                    <p class="card__content">
                      <div className="row">
                        <div className="col-lg-6">
                          {CustomersList.filter(a =>
                            a.meta.student_id_order == item.meta.student_id_order
                            && a.meta.verification_status == "Verified"
                          ).map((item2, index2) => (
                            <span key={index2}>
                              <VerifiedList item={item2} />
                            </span>
                          ))}
                        </div>
                        <div className="col-lg-6">
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            ))} */}
            <DataTable
              columns={columns}
              data={Projects1}
              defaultSortFieldId={1}
              defaultSortAsc={false}
              striped
              // sortIcon={<SortIcon />}
              pagination
              selectableRows
              onSelectedRowsChange={(r) => {
                console.log("asdasd", r)
                setProjects2(r.selectedRows)
              }}
            />
          </>
        }
        {choose == 1 &&
          <DataTable
            columns={columnBatch}
            data={BatchList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
        {choose == 2 &&
          <DataTable
            columns={columnsStudent}
            data={StudentsList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
            selectableRows
          />
        }
      </div >
      {
        Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }

      {
        toStudentModel &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-2 col-1"></div>
            <div className="col-md-8 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setToStudentModal(false)
                    setImportData([])

                  }}
                >X</div>
                <div style={{ display: "none" }} >
                  <input ref={fileRefSingle}
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChange}
                  />
                </div>
                <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
                  onClick={() => {
                    fileRefSingle.current.click()
                  }}
                >Import CSV</div>

                <div style={{ maxHeight: "50vh", overflow: "scroll", backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <table style={{ maxHeight: "50vh" }}>
                    <thead>
                      <tr>
                        <td>SN</td>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Password</td>
                        <td>Phone Number</td>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {Object.values(ImportData).map((item, index) => (
                        <tr key={index}>
                          <TableRow row={item} index={index}
                            ImportData={ImportData} setImportData={setImportData} />
                        </tr>
                      ))} */}
                      <tr>
                        <td colSpan={6} className="center" style={{ cursor: "pointer" }}
                          onClick={() => {
                            setImportData(
                              {
                                ...ImportData,
                                [Object.values(ImportData).length]: {
                                  "title": { rendered: "", },
                                  "meta": {
                                    "batch_name_order": "",
                                    "batch_id_order": "",
                                    "student_id_order": "",
                                    "student_email_order": "",
                                    "student_phone_order": "",
                                    "student_username_order": "",
                                    "paid_amount": "",
                                    "amount_to_pay": "",
                                    "verification_status": "",
                                    "verification_method": "",
                                    "verification_date": "",
                                    "verification_id": ""
                                  }
                                }
                              }
                            )
                          }}
                        >
                          Add New
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row center">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setToStudentModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        const Projects = Object.values(ImportData)
                        for (let i = 0; i < Projects.length; i++) {
                          const item = {
                            title: Projects[i].title.rendered,
                            "status": "publish",
                            meta: Projects[i].meta,
                          }
                          console.log(item)
                          setReload(true)
                          AdsPOSTApi(item, slugs.db_slug_batch_orders)
                            .then((res) => {
                              console.log(item)
                            }).catch(err => {
                              console.log(err)
                            })
                            .finally(() => {
                              if (i == Projects.length - 1) {
                                alert(`${Projects.length} orders added`)
                                setReload(false)
                                GetAll()
                                setToStudentModal(false)
                              }
                            })
                        }
                      }}
                    >
                      Add All Student
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Reload &&
            <div className="modal" style={{ padding: "50vw" }}>
              <div className="dot-pulse"></div>
            </div>
          }
        </section>
      }

      {
        deleteUsersModal &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setDeleteUsersModal(false)
                  }}
                >X</div>
                Do you really want to Delete all the Orders?<br />
                <div className="row">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setDeleteUsersModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          setDeleteUsersModal(false)

                          AdsPUTApi({
                            status: "draft"
                          }, slugs.db_slug_batch_orders, Projects2[i].id)
                            .then((res) => {
                              if (i == Projects2.length - 1) {
                                GetAll()
                              }
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Delete all users
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns}
                    data={Projects2}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      }
    </div >
  )
}